import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <h1>ArkadiemJS</h1>
          <h2>Projects</h2>
          <div className="projects">
            <div className="project-card">Example 1</div>
            <div className="project-card">Example 2</div>
            <div className="project-card">Example 3</div>
            {/* Añade más proyectos aquí */}
          </div>
        </header>
      </div>
  );
}

export default App;
